// Angular custom theme.

// Custom application styles.
@import "./variables.css";
@import "./angular-material-custom-theme.scss";
@import "./elements/style.scss";
@import "./_mixins";

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: var(--font-size);
  font-family: var(--font-family);
}

body {
  margin: 0;
  background-color: var(--base-background-color);
}

h1 {
  font-size: var(--h1-font-size);
}

h2 {
  font-size: var(--h2-font-size);
}

.no-padding {
  .mat-dialog-container {
    padding: 0;
  }
}

.relative .mat-dialog-container {
  position: relative;
}

.nowrap {
  white-space: nowrap;
}

// Do not allow redefine nowrap in pdf
.pdf-container .nowrap {
  white-space: nowrap !important;
}

mat-sidenav .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@include for-mobile {
  :root {
    // Smaller font for mobile
    --font-size: 12px;
  }

  mat-sidenav .mat-drawer-inner-container {
    overflow: auto;
    height: auto;
  }
}
