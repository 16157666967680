button {
  border: 0;
  border-radius: var(--radius);
  cursor: pointer;
}

.mat-raised-button.mat-primary {
  background-color: var(--primary-button-color);
  color: var(--primary-contrast-color);
}
