/* Custom application color and font settings */
:root {
  /* Font */
  --font-size: 14px;
  --font-family: sans-serif;
  --h1-font-size: 21px;
  --h2-font-size: 14px;

  /* Styles that don't represent the brand (stole this phrase from material.io) */

  /* Base color for a body text. */
  --base-color: #000000;

  /* Background color appears behind scrollable content. */
  --base-background-color: #e3e5e7;

  /* Color for a text that's not important or it should not draw attention. */
  --faint-color: hsl(0, 0%, 70%);
  --faint-dark-color: hsl(0, 0%, 40%);
  --faint-light-color: hsl(0, 0%, 85%);

  /* Font color for surface background. */
  --surface-font-color: #000000;
  /* Affect surfaces of components, such as cards, sheets, and menus */
  --surface-background-color: #ffffff;

  /* Font color for error messages. */
  --error-font-color: #ee5757;
  /* In case error is a block with a message inside. */
  --error-background-color: #ffffff;

  /* Elements spacing. */
  --space-m: 15px;
  --space-xs: calc(var(--space-m) * 0.25);
  --space-s: calc(var(--space-m) * 0.5);
  --space-l: calc(var(--space-m) * 1.5);
  --space-xl: calc(var(--space-m) * 2.5);

  /* Brand colors */
  --primary-color: #bf217a;
  --primary-dark-color: #9b0b5c;
  --primary-light-color: #dd8fbb;
  --primary-contrast-color: #ffffff;
  --primary-dark-contrast-color: white;
  --primary-light-contrast-color: black;

  --accent-color: #6c459e;
  --accent-dark-color: #3f2774;
  --accent-light-color: #c5cae9;
  --accent-contrast-color: white;
  --accent-dark-contrast-color: white;
  --accent-light-contrast-color: black;

  --warn-color: #da3e33;
  --warn-dark-color: #aa190f;
  --warn-light-color: #e4746c;
  --warn-contrast-color: white;
  --warn-dark-contrast-color: white;
  --warn-light-contrast-color: black;

  /* Util properties. Util properties must not define new values. */
  --radius: calc(var(--font-size) * 0.75);
  --border-color: var(--faint-light-color);

  /* Add your variables below, please */
  --primary-button-color: #0eb1e8;
  --success-color: #27b462;
  --sidebar-background-color: black;
  --placeholder-wave-color: #f6f8f8;
  --placeholder-light-color: #eef3f5;
  --placeholder-dark-color: #cbcecf;
  --input-underline-color: #2a2d35;
  --input-background-color: #151515;
  --backdrop-color: rgba(0, 0, 0, 0.6);
  --sidebar-width: 350px;
  --arrow-color: #adadad;
  --primary-title-color: #0eb1e8;
  --overlay-color: rgba(0, 0, 0, 0.11);
  --export-button-background-color: #fafafa;
  --tooltip-background-color: #323232;
  --notes-column-width: 200px;
}

@media only screen and (min-width: 1400px) {
  :root {
    --notes-column-width: 40%;
  }
}