::placeholder {
  color: var(--placeholder-color);
  font-style: italic;
}

.mat-form-field-appearance-fill {
  .mat-form-field-ripple {
    background-color: var(--primary-button-color);
  }

  .mat-select-arrow,
  .mat-select-value,
  .mat-select-placeholder,
  .mat-form-field-label {
    color: var(--primary-contrast-color);
  }

  &.mat-form-field.mat-focused {
    .mat-form-field-label {
      color: var(--primary-contrast-color);
    }

    .mat-form-field-ripple {
      background-color: var(--primary-button-color);
    }

    .mat-select-arrow {
      color: var(--primary-button-color);
    }
  }

  &.mat-form-field .mat-select-disabled {
    .mat-select-arrow {
      color: var(--faint-dark-color);
    }
  }

  .mat-form-field-flex {
    background-color: var(--input-background-color);
    /* We need this to override Material styles. */
    border-radius: 0 !important;
  }

  &.mat-form-field-disabled {
    .mat-form-field-flex {
      background-color: var(--input-background-color);
    }
  }

  .mat-form-field-infix {
    border: 0;
  }

  &.mat-form-field-type-mat-input {
    .mat-form-field-infix {
      padding: 0.25em 0 0.5em 0;
    }

    .mat-form-field-flex {
      padding: 0.4em 0.75em 0 0.75em;
    }
  }

  .mat-select-arrow-wrapper {
    /* We need this to override Material styles. */
    transform: none !important;
  }

  .mat-form-field-underline {
    bottom: 0;
  }

  .mat-form-field-underline::before {
    background-color: var(--input-underline-color);
  }

  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-input-element {
    caret-color: var(--primary-button-color);

    &:disabled {
      color: var(--faint-dark-color);
    }
  }
}

.mat-slider-track-background {
  background-color: var(--input-underline-color);
}

.mat-slider-min-value {
  .mat-slider-focus-ring {
    background-color: var(--accent-color);
  }

  &.mat-accent:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: var(--accent-color);
  }

  &.mat-primary:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: var(--primary-color);
  }
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: var(--faint-dark-color);
}

.mat-primary .mat-radio-outer-circle {
  border-color: var(--primary-contrast-color);
}

.white-checkbox .mat-checkbox-frame {
  border-color: var(--primary-contrast-color);
  /* We need this to override Material styles. */
  border-width: 1px !important;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: var(--faint-dark-color);
}

.checkbox-long-label .mat-checkbox-label {
  white-space: normal;
  line-height: 1em;
}

.mat-slider-horizontal {
  /* We need this to override Material styles. */
  height: var(--space-l) !important;

  .mat-slider-wrapper {
    /* We need this to override Material styles. */
    top: var(--space-m) !important;
    left: 0 !important;
  }
}

.mat-form-field {
  .iti__country-list {
    max-width: 250px;
    max-height: 150px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  /* We need this to hide arrows. */
  -moz-appearance: textfield;
}
