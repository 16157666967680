.overall-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  z-index: 10001;
}

.overall-container .overall-spinner {
  position: absolute;
  top: 300px;
}
