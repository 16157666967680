.export-table {
  th,
  td {
    border-left: 1px solid var(--border-color);
    padding: var(--space-xs) !important;
  }

  th:first-child,
  td:first-child {
    color: var(--primary-title-color);
    border-left: none;
  }

  tr,
  thead {
    border-top: 1px solid var(--border-color);
  }

  thead {
    border-top: none;
  }

  tr:last-child,
  cpcac-calculations-table-row:last-child {
    border-bottom: 1px solid var(--border-color);
  }
}

.export-table {
  overflow-x: hidden;

  th,
  td {
    width: auto !important;

    &:first-child {
      width: 150px !important;
    }

    &:last-child:not(:nth-child(2)) {
      width: 250px !important;
    }
  }
}
