@import '~@angular/material/theming';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$cpca-primary: (
  main: var(--primary-color),
  dark: var(--primary-dark-color),
  light: var(--primary-light-color),
  contrast: (
    main: var(--primary-contrast-color),
    dark: var(--primary-dark-contrast-color),
    light: var(--primary-light-contrast-color),
  )
);

$cpca-accent: (
  main: var(--accent-color),
  dark: var(--accent-dark-color),
  light: var(--accent-light-color),
  contrast: (
    main: var(--accent-contrast-color),
    dark: var(--accent-dark-contrast-color),
    light: var(--accent-light-contrast-color),
  )
);

$cpca-warn: (
  main: var(--warn-color),
  dark: var(--warn-dark-color),
  light: var(--warn-light-color),
  contrast: (
    main: var(--warn-contrast-color),
    dark: var(--warn-dark-contrast-color),
    light: var(--warn-light-contrast-color),
  )
);

/*
  Signature of mat-palette.

  mat-palette(
    scss variable with shape { key1: color, key2: color, key3: color, contrast: { ...repeat all keys } }, (example above)
    key of primary color,
    key of darken color,
    key of lighten color,
    (optional) key of primary contrast - default contrast[key_of_primary_color]
  )
*/

$app-primary: mat-palette($cpca-primary, main, dark, light);
$app-accent: mat-palette($cpca-accent, main, dark, light);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($cpca-warn, main, dark, light);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);