@import "./controls.scss";
@import "./buttons.scss";
@import "./loader.scss";
@import "./tables.scss";
@import "../_variables.scss";

@media only screen and (max-width: $breakpoint-mobile) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: $breakpoint-mobile) {
  .hide-on-desktop {
    display: none !important;
  }
}
